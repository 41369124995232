import { useLocation } from '@reach/router'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'
import { BlogPostQuery } from '../../types/graphql-types'
import StructuredData from '../const/structuredData'
import DefaultLayout from '../layouts/DefaultLayout'
import HeadMeta from '../components/organisms/HeadMeta'
import Ad from '../components/atoms/ad/Ad'
import { colors } from '../const/colors'
import { useSiteMetaData } from '../hooks/useSiteMetaData'
import LinkButtonDefault from '../components/atoms/buttons/LinkButtonDefault'
import UrlCopyButton from '../components/atoms/buttons/UrlCopyButton'
import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'
import typescript from 'highlight.js/lib/languages/typescript'
import php from 'highlight.js/lib/languages/php'
import bash from 'highlight.js/lib/languages/bash'
import shell from 'highlight.js/lib/languages/shell'
import yaml from 'highlight.js/lib/languages/yaml'
import plaintext from 'highlight.js/lib/languages/plaintext'
import json from 'highlight.js/lib/languages/json'
import xml from 'highlight.js/lib/languages/xml'
import css from 'highlight.js/lib/languages/css'
import scss from 'highlight.js/lib/languages/scss'
import stylus from 'highlight.js/lib/languages/stylus'
import 'highlight.js/styles/atom-one-dark.css'
import { joinSpace, removeTagsAndLb } from '../libs/utils'
import * as styles from '../css/pages/post.module.styl'

interface IfProps {
  data: BlogPostQuery
}

hljs.registerLanguage('javascript', javascript)
hljs.registerLanguage('typescript', typescript)
hljs.registerLanguage('ts', typescript)
hljs.registerLanguage('js', javascript)
hljs.registerLanguage('php', php)
hljs.registerLanguage('bash', bash)
hljs.registerLanguage('shell', shell)
hljs.registerLanguage('yaml', yaml)
hljs.registerLanguage('yml', yaml)
hljs.registerLanguage('plain-text', plaintext)
hljs.registerLanguage('plain', plaintext)
hljs.registerLanguage('json', json)
hljs.registerLanguage('html', xml)
hljs.registerLanguage('xml', xml)
hljs.registerLanguage('svg', xml)
hljs.registerLanguage('css', css)
hljs.registerLanguage('sass', scss)
hljs.registerLanguage('scss', scss)
hljs.registerLanguage('stylus', stylus)

/**
 * 渡された日付文字列をその日の始まりにする
 * @param date
 */
const startDay = (date?: string): Date => {
  const d = date != null ? new Date(date) : new Date()
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)

  return d
}

/**
 * 更新日の方が1日以上進んでいる
 * @param date
 * @param modified
 */
const isModified = (date: Date, modified: Date): boolean => {
  return modified.getTime() > date.getTime()
}

/**
 * 何年経過したか
 * @param date
 */
const yearsElapsed = (date: Date): number => {
  // 86400000は1日をミリ秒換算した値
  const termDay = (startDay().getTime() - date.getTime()) / 86400000
  return Math.floor(termDay / 365)
}

const BlogPostPage: React.FC<IfProps> = ({ data: { post, next, prev } }) => {
  // 存在しない場合は自動で404に遷移するが、型として存在しないことがあり得るためのフォールバック
  if (post == null) return <div />

  const bodyRef = React.createRef<HTMLDivElement>()

  useEffect(() => {
    // highlight.jsの有効化
    document.querySelectorAll('pre code').forEach((el) => {
      hljs.highlightBlock(el as HTMLElement)
    })

    // 外部リンクを target=_blank にする
    const links = document.querySelectorAll('a')
    links.forEach((link: Element) => {
      if (link.getAttribute('target') === '_blank') return

      const href = link.getAttribute('href')
      if (href == null) return
      if (/^https?:\/\//.test(href)) {
        link.setAttribute('target', '_blank')
        return
      }

      // スラッシュやハッシュで始まっていない場合は遷移できるようにする
      if (!/^([\/#])/.test(href)) {
        link.setAttribute('href', `/${href}`)
      }
    })

    // WordPressのCodePen embedなどで挿入されているscriptタグを抜き出して再配置してscriptタグを有効化
    const bodyElem = bodyRef.current as HTMLDivElement
    const scripts = bodyElem.querySelectorAll('script')
    const scriptsArr: string[] = []

    scripts.forEach((s) => {
      const src = s.getAttribute('src')

      if (src !== null && scriptsArr.indexOf(src) === -1) {
        const tag = document.createElement('script')
        tag.setAttribute('src', src)
        tag.setAttribute('async', 'async')
        tag.setAttribute('defer', 'defer')
        bodyElem.appendChild(tag)
        scriptsArr.push(src)
      }

      s.remove()
    })
  })

  const config = useSiteMetaData()
  const { defaultImage, siteUrl, defaultDescription } = config
  const { pathname } = useLocation()
  const copyUrl = siteUrl + pathname

  const {
    title,
    excerpt,
    featuredImage,
    content,
    date,
    dateGmt,
    modified,
    modifiedGmt,
    categories,
    tags,
    link
  } = post

  const modifiedYearsElapsed = yearsElapsed(startDay(modifiedGmt))

  // Structured Data
  const sd$ = new StructuredData(config)
  const sdPost = sd$.article(
    title as string,
    removeTagsAndLb(excerpt as string) || defaultDescription,
    link as string,
    dateGmt as string,
    modifiedGmt as string,
    {
      url: featuredImage?.node?.localFile?.childImageSharp?.resize?.src || '',
      width:
        featuredImage?.node?.localFile?.childImageSharp?.resize?.width || 0,
      height:
        featuredImage?.node?.localFile?.childImageSharp?.resize?.height || 0
    }
  )

  const breadcrumbsCategory: { id: string; name: string } | undefined = ((
    cats
  ) => {
    if (cats == null) return undefined
    if (cats.nodes == null) return undefined
    if (cats.nodes[0] == null) return undefined

    return {
      id: cats.nodes[0].link as string,
      name: cats.nodes[0].name as string
    }
  })(categories)

  const breadcrumbsArgs: { id: string; name: string }[] = []
  if (breadcrumbsCategory != null) breadcrumbsArgs.push(breadcrumbsCategory)
  breadcrumbsArgs.push({ id: link as string, name: title as string })

  const sdBreadcrumbs = sd$.breadcrumbs(breadcrumbsArgs)

  return (
    <DefaultLayout>
      {' '}
      <HeadMeta
        ogType="article"
        title={title as string}
        description={removeTagsAndLb(excerpt || undefined)}
        image={featuredImage?.node?.localFile?.publicURL || undefined}
        structuredDataBreadcrumbs={sdBreadcrumbs}
        structuredDataArticle={sdPost}
      />
      <article>
        {/* 1年以上経っている場合の警告 */}{' '}
        {modifiedYearsElapsed > 0 && (
          <div
            className={styles.postYearsElapsed}
          >{`この記事は最終更新日から${modifiedYearsElapsed}年以上経過しているため正確ではないかもしれません`}</div>
        )}
        <h1 className={joinSpace([styles.postHeadline])}>{title}</h1>
        <div className={joinSpace([styles.postBlock, styles.postHeader])}>
          <ul className={joinSpace([styles.postDatetime])}>
            <li>
              <span>投稿日：</span>
              <time dateTime={dateGmt}>{date}</time>
            </li>
            {isModified(startDay(dateGmt), startDay(modifiedGmt)) && (
              <li>
                <span>最終更新日：</span>
                <time dateTime={modifiedGmt}>{modified}</time>
              </li>
            )}
          </ul>
          <div className={joinSpace(['s-pt-2'])}>
            <UrlCopyButton url={copyUrl} />
          </div>
        </div>
        {/* AD */}
        <aside className={joinSpace(['pt-10', 'pb-10'])}>
          <Ad
            adSlot="1168000930"
            adFormat="auto"
            adResponsive="true"
            adStyle={{ display: 'block' }}
          />
        </aside>
        {/* Image */}{' '}
        {featuredImage &&
        featuredImage.node &&
        featuredImage.node.localFile &&
        featuredImage.node.localFile.childImageSharp ? (
          <GatsbyImage
            image={featuredImage.node.localFile.childImageSharp.gatsbyImageData}
            className={styles.postKeyVisual}
            alt=""
          />
        ) : (
          <img
            src={defaultImage}
            className={styles.postKeyVisual}
            width="1200"
            height="630"
            alt=""
          />
        )}{' '}
        {/* Content */}
        <div
          ref={bodyRef}
          className={joinSpace([styles.postContent, styles.postBlock])}
          dangerouslySetInnerHTML={{ __html: content as string }}
        />
      </article>
      {/* Categories & Tags*/}{' '}
      {((categories && categories.nodes) || (tags && tags.nodes)) && (
        <aside className={joinSpace([styles.postBlock])}>
          {categories && categories.nodes && (
            <ul className={joinSpace([styles.postCategoriesTags])}>
              {categories.nodes.map((cat) => {
                if (cat == null) return
                return (
                  <li key={cat.id}>
                    <LinkButtonDefault
                      target={cat.uri as string}
                      name={cat.name as string}
                      color={colors.red}
                      classes={[styles.postTag]}
                    />
                  </li>
                )
              })}
            </ul>
          )}{' '}
          {tags && tags.nodes && (
            <ul className={joinSpace([styles.postCategoriesTags])}>
              {tags.nodes.map((tag) => {
                if (tag == null) return
                return (
                  <li key={tag.id}>
                    <LinkButtonDefault
                      target={tag.uri as string}
                      name={`# ${tag.name}`}
                      color={colors.red}
                      classes={[styles.postTag]}
                    />
                  </li>
                )
              })}
            </ul>
          )}
        </aside>
      )}{' '}
      <div className={joinSpace([styles.postBlock])}>
        <UrlCopyButton url={copyUrl} />
      </div>
      {/* Pager */}
      <nav className={joinSpace([styles.postBlock])}>
        <ul className={joinSpace([styles.postPagination])}>
          <li className={joinSpace([styles.postPaginationNewer])}>
            <span
              className={joinSpace([
                'ff-headline',
                styles.postPaginationHeadline
              ])}
            >
              Newer Post
            </span>{' '}
            {prev ? (
              <Link
                to={prev.uri as string}
                className={joinSpace([styles.postPaginationLink])}
              >
                {' '}
                <i
                  className={joinSpace([
                    'fas',
                    'fa-angle-double-left',
                    styles.postPaginationIconSpaceRight
                  ])}
                />{' '}
                {prev.title}
              </Link>
            ) : (
              <span
                className={joinSpace([
                  'ff-headline',
                  styles.postPaginationNoLink
                ])}
              >
                Coming soon...
              </span>
            )}
          </li>
          <li className={joinSpace([styles.postPaginationOlder])}>
            <span
              className={joinSpace([
                'ff-headline',
                styles.postPaginationHeadline
              ])}
            >
              Older Post
            </span>{' '}
            {next ? (
              <Link
                to={next.uri as string}
                className={joinSpace([styles.postPaginationLink])}
              >
                {next.title}{' '}
                <i
                  className={joinSpace([
                    'fas',
                    'fa-angle-double-right',
                    styles.postPaginationIconSpaceLeft
                  ])}
                />{' '}
              </Link>
            ) : (
              <span
                className={joinSpace([
                  'ff-headline',
                  styles.postPaginationNoLink
                ])}
              >
                This post is most older
              </span>
            )}
          </li>
        </ul>
      </nav>
      {/* AD */}
      <div className={joinSpace(['pt-10', 'pb-10'])}>
        <Ad
          adSlot="4121467339"
          adFormat="auto"
          adResponsive="true"
          adStyle={{ display: 'block' }}
        />
      </div>
    </DefaultLayout>
  )
}

export default BlogPostPage

export const query = graphql`
  query BlogPost($slug: String!, $next: String, $prev: String) {
    post: wpPost(slug: { eq: $slug }, status: { eq: "publish" }) {
      ...flagPostDefault
      excerpt
      content
      link
      categories {
        nodes {
          id
          name
          uri
          link
        }
      }
      tags {
        nodes {
          id
          name
          uri
        }
      }
    }

    next: wpPost(id: { eq: $next }) {
      title
      uri
    }

    prev: wpPost(id: { eq: $prev }) {
      title
      uri
    }
  }
`
