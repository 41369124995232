import animejs from 'animejs'
import ClipboardJS from 'clipboard'
import React, { useEffect } from 'react'
import { asyncScheduler, pipe, scheduled } from 'rxjs'
import { map } from 'rxjs/operators'
import { joinSpace } from '../../../libs/utils'
import * as styles from './url-copy-button.module.styl'

interface IfProps {
  url: string
}

const UrlCopyButton: React.FC<IfProps> = ({ url }) => {
  const copyButton = React.createRef<HTMLButtonElement>()
  const toolTip = React.createRef<HTMLSpanElement>()
  const toolTipContainer = React.createRef<HTMLSpanElement>()

  useEffect(() => {
    const clipboard = new ClipboardJS(copyButton.current as HTMLButtonElement)
    const tip = toolTip.current as HTMLSpanElement
    const tipContainer = toolTipContainer.current as HTMLSpanElement

    const animate = (data: { result: string; copy: string }) =>
      scheduled([data], asyncScheduler).pipe(
        map(({ result, copy }) => {
          tipContainer.setAttribute('data-result', result)
          tipContainer.textContent = copy
        }),
        map(() => {
          animejs({
            targets: tip,
            easing: 'linear',
            opacity: [
              { value: 1, duration: 100, endDelay: 400 },
              { value: 0, duration: 100 }
            ],
            translateY: [
              {
                value: -20,
                duration: 100,
                endDelay: 400,
                easing: 'easeOutQuart'
              },
              { value: 0, duration: 100, easing: 'easeOutCirc' }
            ]
          })
        })
      )

    clipboard.on('success', (e) => {
      animate({ result: 'success', copy: 'Copied :)' }).subscribe(() => {
        e.clearSelection()
      })
    })

    clipboard.on('error', (e) => {
      animate({ result: 'failed', copy: 'Failed X(' }).subscribe(() => {
        e.clearSelection()
      })
    })
  })

  return (
    <div className={joinSpace([styles.urlCopy])}>
      <button
        type="button"
        className={joinSpace([styles.urlCopyButton])}
        ref={copyButton}
        data-clipboard-text={url}
      >
        <i className={joinSpace(['far', 'fa-copy', styles.urlCopyIcon])} />{' '}
        <span className={joinSpace([styles.urlCopyText])}>URL COPY</span>
      </button>
      <span className={joinSpace([styles.urlCopyResult])} ref={toolTip}>
        <span
          className={joinSpace([styles.urlCopyResultContainer])}
          ref={toolTipContainer}
        />
      </span>
    </div>
  )
}

export default UrlCopyButton
