// extracted by mini-css-extract-plugin
export var postBlock = "post-module--post-block--3qKg0";
export var postYearsElapsed = "post-module--post-years-elapsed--3wGS_";
export var postHeadline = "post-module--post-headline--2cZgi";
export var postHeader = "post-module--post-header--3M9tF";
export var postDatetime = "post-module--post-datetime--1EMLd";
export var postKeyVisual = "post-module--post-key-visual--Xiird";
export var postContent = "post-module--post-content--2W56a";
export var postPagination = "post-module--post-pagination--3JN4v";
export var postPaginationNewer = "post-module--post-pagination-newer--2Bh7f";
export var postPaginationOlder = "post-module--post-pagination-older--3htZk";
export var postPaginationIconSpaceLeft = "post-module--post-pagination-icon-space-left--2BenV";
export var postPaginationIconSpaceRight = "post-module--post-pagination-icon-space-right--2V0XY";
export var postPaginationHeadline = "post-module--post-pagination-headline--znNrF";
export var postPaginationLink = "post-module--post-pagination-link--21E67";
export var postPaginationNoLink = "post-module--post-pagination-no-link--3wSL8";
export var postCategoriesTags = "post-module--post-categories-tags--wIxh2";
export var postTag = "post-module--post-tag--2of-2";